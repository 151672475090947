import numberFormats from "@/config/i18n/numberFormats.ts";

import en from "./en.json";
import ko from "./ko.json";
import pages from "./pages";

export default defineI18nConfig(() => {
  return {
    locale: process.env.CURRENT_DOMAIN,
    fallbackLocale: process.env.CURRENT_DOMAIN,
    messages: {
      en,
      ko,
    },
    datetimeFormats: {
      en: {
        longDate: {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        month: "long",
      },
      ko: {
        longDate: {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        month: "long",
      },
    },
    numberFormats,
    parsePages: false,
    pages,
  };
});
