// used for i18n
export default {
  index: {
    en: "/",
    ko: "/",
  },
  "project/[uid]": {
    en: "/project/[uid]",
    ko: "/project/[uid]",
  },
  project: {
    en: "/project",
    ko: "/project",
  },
  "bim/[uid]": {
    en: "/bim/[uid]",
    ko: "/bim/[uid]",
  },
  bim: {
    en: "/bim",
    ko: "/bim",
  },
  "slice-simulator": {
    en: "/slice-simulator",
    ko: "/slice-simulator",
  },
  about: {
    en: "/about",
    ko: "/about",
  },
  contact: {
    en: "/contact",
    ko: "/contact",
  },
};
