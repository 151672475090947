export default {
  en: {
    number: {
      maximumFractionDigits: 2,
    },
    score: {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
    },
    price: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  de: {
    number: {
      maximumFractionDigits: 2,
    },
    score: {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    currency: {
      style: "currency",
      currency: "EUR",
      currencyDisplay: "symbol",
      minimumFractionDigits: 0,
    },
    price: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};
